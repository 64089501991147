<template>
	<div class="page" v-loading="loading">
		<div class="continer-wrapper">
			<div class="device-list">
				<base-title text="设备列表"></base-title>
				<div class="device-box">
					<device-list
						:single-select="false"
						:deviceList="contrastDeviceList"
						@select-change="handleSelectChange"
					></device-list>
				</div>
			</div>
			<div class="echarts-list">
				<base-title text="实时监测"></base-title>
				<div class="right-box">
					<gc-charts-box
						ref="charts-box"
						chartType="gas"
						:currentDevices="currentDevices"
						:echartsFlag="true"
						:params.sync="params"
						@analysis="handleAnalysis"
					></gc-charts-box>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import BaseTitle from '../components/BaseTitle.vue'
import DeviceList from '../components/DeviceList/index.vue'
import { queryStatusByDevices } from '@/apis/alarm.api.js'
import { isBlank } from '@/utils/validate'
export default {
	name: 'contrastAnalysis',
	components: {
		BaseTitle,
		DeviceList,
	},
	data() {
		return {
			params: {
				time: null, //时间区间
			},
			currentIds: [],
			currentDevices: [],
			deviceIds: [],
			contrastDeviceList: [],
			loading: false,
		}
	},
	methods: {
		getList(checked) {
			this.loading = true
			let obj = {}
			for (var key in this.params) {
				let val = this.params[key]
				if (key === 'time') {
					// 如果没有时间则默认取最近一天内
					if (isBlank(val)) {
						// 一天内
						this.params.time = [
							this.dayjs(new Date().getTime() - 3600 * 1000 * 24).format('YYYY-MM-DD HH:mm:ss'),
							this.dayjs().format('YYYY-MM-DD HH:mm:ss'),
						]
					}
					obj['startTime'] = this.dayjs(this.params.time[0]).format('YYYY-MM-DD HH:mm:ss')
					obj['endTime'] = this.dayjs(this.params.time[1]).format('YYYY-MM-DD HH:mm:ss')
				} else {
					!isBlank(val) ? (obj[key] = val) : null
				}
			}
			obj['attributeKey'] =
				'standardCumulativeFlow,workCumulativeFlow,standardInstantaneousFlow,workInstantaneousFlow,pressure,gasTemperature'
			if (checked) {
				obj['attributeKey'] = ''
				let attributeKeyArr = []
				checked.forEach(key => {
					if (key === 'instantaneousFlow') {
						attributeKeyArr.push('standardInstantaneousFlow', 'workInstantaneousFlow')
					} else {
						attributeKeyArr.push(key)
					}
				})
				obj['attributeKey'] = attributeKeyArr.join(',')
			}
			if (!obj['attributeKey'].length) {
				this.loading = false
				return
			}
			queryStatusByDevices({
				...obj,
				deviceIds: this.deviceIds,
			})
				.then(res => {
					this.currentDevices = []
					this.contrastDeviceList = res || []
					this.contrastDeviceList.forEach(item => {
						if (this.currentIds.includes(item.deviceId)) {
							item.checked = true
							this.currentDevices.push(item)
						} else {
							item.checked = false
						}
					})
					// this.currentDevices = this.contrastDeviceList;
				})
				.finally(() => {
					this.loading = false
				})
		},
		handleSelectChange(item, chooseArr) {
			this.currentDevices = chooseArr.length ? chooseArr : this.currentDevices
			this.currentIds = this.currentDevices.map(item => item.deviceId)
		},
		handleAnalysis(checked) {
			this.getList(checked)
		},
	},
	created() {
		const { deviceIds } = this.$route.query
		this.deviceIds = deviceIds.split(',')
		this.currentIds = this.deviceIds.map(Number)
		this.getList()
	},
}
</script>
<style lang="scss" scoped>
@import '../../runLayout.scss';
.continer-wrapper {
	height: 100%;
	padding: 24px;
	display: flex;
	overflow: hidden;
	.device-list {
		width: 412px;
		height: 100%;
		flex-shrink: 0;
		border-right: 0.5px dashed #999999;
		.device-box {
			height: calc(100% - 24px);
			overflow-y: auto;
			overflow-x: hidden;
		}
	}
	.echarts-list {
		flex: 1;
		padding-left: 20px;
	}
	.right-box {
		padding-top: 20px;
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: auto;
		&-title {
			font-size: 16px;
			line-height: 24px;
			font-weight: 600;
			padding-bottom: 20px;
		}
		.pressure-tap-search {
			display: flex;
			padding-bottom: 20px;
			.ops-btn {
				padding-left: 20px;
				@include base-button(80px);
			}
		}
		.pressure-tap-echarts-box {
			flex: 1;
			width: 100%;
			overflow: auto;
		}
	}
}
</style>
